import React, { useState, useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import Mustache from "mustache";
import { SIGNATURE } from "./signature";
import { LANGUAGE } from "./language";

function App() {
  const [data, setData] = useState({
    name: "John Doe",
    position: "Chief Signature Officer",
    email: "john.doe@inheaden.io",
  });

  const [signature, setSignature] = useState("");
  const [language, setLanguage] = useState("en");
  const [darkMode, setDarkMode] = useState(false);
  const [inheadenCountry, setInheadenCountry] = useState("");

  useEffect(() => {
    const savedData = localStorage.getItem("data");
    console.log(savedData);
    if (savedData) {
      try {
        setData(JSON.parse(savedData));
      } catch {}
    }
  }, []);

  useEffect(() => {
    const followMeLinks = [
      "linkedIn",
      "facebook",
      "medium",
      "twitter",
      "instagram",
      "xing",
    ]
      .filter((key) => !!data[key])
      .map((key) => ({
        name: key[0].toUpperCase() + key.slice(1),
        url: data[key],
      }));
    followMeLinks.forEach((value, index) => {
      value.pipe = index < followMeLinks.length - 1;
    });
    const s = Mustache.render(SIGNATURE, {
      ...data,
      ...LANGUAGE[language],
      followMe: followMeLinks.length ? followMeLinks : undefined,
    });

    setSignature(s.replace(/\n/g, "").replace(/>\s*/g, ">"));
    localStorage.setItem("data", JSON.stringify(data));
  }, [data, language]);

  function setFormData(key, event) {
    setData({
      ...data,
      [key]: event.target.value,
    });
  }

  return (
    <div className="App">
      <div>
        <header className="App-header">
          <img src={logo} className="App-logo" alt="Inheaden Logo" />
        </header>
      </div>

      <div className="container">
        <div
          style={{
            margin: "2rem",
            textAlign: "center",
          }}
        >
          <p style={{ margin: 0, fontWeight: 700 }}>
            Hey there, here you can create your own Inheaden signature.
          </p>
          <p>
            Simply fill in your details below, then copy the signature using the
            button at the bottom of the page.
          </p>
        </div>

        <div className="Forms row">
          <div className="form-group col-12 col-md-6">
            <label htmlFor="your-name">Your name</label>
            <input
              className="form-control"
              id="your-name"
              placeholder="John Doe"
              value={data.name}
              onChange={(e) => setFormData("name", e)}
            />
          </div>
          <div className="form-group col-12 col-md-6">
            <label htmlFor="your-position">Your position</label>
            <input
              className="form-control"
              id="your-position"
              placeholder="Chief Signature Officer"
              value={data.position}
              onChange={(e) => setFormData("position", e)}
            />
          </div>
          <div className="form-group col-12 col-md-6">
            <label htmlFor="your-mobile">Your mobile number</label>
            <input
              className="form-control"
              id="your-mobile"
              placeholder="+49 0000 0000"
              value={data.mobile}
              onChange={(e) => setFormData("mobile", e)}
            />
          </div>
          <div className="form-group col-12 col-md-6">
            <label htmlFor="your-landline">Your landline number</label>
            <input
              className="form-control"
              id="your-mobile"
              placeholder="+49 0000 0000"
              value={data.landline}
              onChange={(e) => setFormData("landline", e)}
            />
          </div>
          <div className="form-group col-12 col-md-6">
            <label htmlFor="your-email">Your mail address</label>
            <input
              className="form-control"
              id="your-email"
              placeholder="john.doe@inheaden.io"
              value={data.email}
              onChange={(e) => setFormData("email", e)}
            />
          </div>
          <div className="form-group col-12 col-md-6">
            <label htmlFor="linkedin">LinkedIn</label>
            <input
              className="form-control"
              id="linkedin"
              value={data.linkedIn}
              onChange={(e) => setFormData("linkedIn", e)}
            />
          </div>
          <div className="form-group col-12 col-md-6">
            <label htmlFor="twitter">X</label>
            <input
              className="form-control"
              id="twitter"
              value={data.twitter}
              onChange={(e) => setFormData("twitter", e)}
            />
          </div>
          <div className="form-group col-12 col-md-6">
            <label htmlFor="medium">Medium</label>
            <input
              className="form-control"
              id="medium"
              value={data.medium}
              onChange={(e) => setFormData("medium", e)}
            />
          </div>
          <div className="form-group col-12 col-md-6">
            <label htmlFor="facebook">Facebook</label>
            <input
              className="form-control"
              id="facebook"
              value={data.facebook}
              onChange={(e) => setFormData("facebook", e)}
            />
          </div>
          <div className="form-group col-12 col-md-6">
            <label htmlFor="instagram">Instagram</label>
            <input
              className="form-control"
              id="instagram"
              value={data.instagram}
              onChange={(e) => setFormData("instagram", e)}
            />
          </div>
          <div className="form-group col-12 col-md-6">
            <label htmlFor="xing">Xing</label>
            <input
              className="form-control"
              id="xing"
              value={data.xing}
              onChange={(e) => setFormData("xing", e)}
            />
          </div>
          <div className=" d-flex flex-column justify-content-start form-group col-12 col-md-6">
            <label htmlFor="select-country" className="mx-auto">
              Please select your country:
            </label>
            <div
              className="d-flex align-items-start flex-row "
              data-toggle="buttons"
            >
              <button
                type="button"
                className="btn btn-secondary mx-auto"
                onClick={() => {
                  setLanguage("en");
                  setInheadenCountry("Inheaden GmbH");
                }}
              >
                GERMANY
              </button>
              <button
                type="button"
                className="btn btn-secondary mx-auto "
                onClick={() => {
                  setLanguage("en_uae");
                  setInheadenCountry("Inheaden L.L.C-FZ");
                }}
              >
                UAE
              </button>
              <button
                type="button"
                className="btn btn-secondary mx-auto"
                onClick={() => {
                  setLanguage("en_india");
                  setInheadenCountry("Inheaden India Pvt. Ltd.");
                }}
              >
                INDIA
              </button>
            </div>
          </div>
        </div>

        <hr />

        <div className="Signature">
          <p>This is how your signature will look like:</p>
          <button
            className="btn btn-sm btn-primary mr-2"
            onClick={() => setDarkMode(!darkMode)}
          >
            {darkMode ? "Light mode" : "Dark mode"}
          </button>
          {(language === "en" || language === "de") && (
            <button
              className="btn btn-sm btn-primary"
              onClick={() => setLanguage(language === "en" ? "de" : "en")}
            >
              {language === "en" ? "Switch to German" : "Switch to English"}
            </button>
          )}
        </div>
        <div
          className={"mt-3 p-3" + (darkMode ? " dark" : "")}
          dangerouslySetInnerHTML={{ __html: signature }}
        ></div>

        <hr />

        <div className="Signature-Code">
          <p>
            Copy all the content from this textarea and use it as your
            signature.
          </p>
          <p>
            To learn how to apply the signature using your mail client, just
            google the name (e.g. Outlook or Apple Mail) plus "add html
            signature".
          </p>
          <button
            className="btn btn-sm btn-primary mb-3"
            onClick={() => navigator.clipboard.writeText(signature)}
          >
            Copy to clipboard
          </button>
          <form>
            <div className="form-group">
              <textarea
                className="form-control"
                value={signature}
                rows="20"
                readOnly={true}
              ></textarea>
            </div>
          </form>
        </div>

        <footer style={{ textAlign: "center", margin: "1rem 0" }}>
          &copy; {new Date().getFullYear()}
          {inheadenCountry}-{" "}
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://inheaden.io/imprint"
          >
            Imprint
          </a>{" "}
          -{" "}
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://inheaden.io/privacy"
          >
            Privacy
          </a>
        </footer>
      </div>
    </div>
  );
}

export default App;
